import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import { container, articleImage, articleDate, textContainer, fakeLink, articleTitle, articleExcerpt } from "./blog.module.css"
const BlockContent = require('@sanity/block-content-to-react')

const ArticleCard = ({article}) => {
  return (
    <Link
      to={`${article.slug.current}`}
      aria-label={`View blog article`}
      className={container}
    >
      <div className={articleImage}>
        <GatsbyImage
          alt={""}
          image={article.mainImage.asset.gatsbyImageData}
          loading={"lazy"}
          placeholder="none"
          style={{height: "100%", width: "100%", overflow:"hidden"}}
          imgStyle={{ objectFit: "cover"}}
        />      
      </div>
      <div className={textContainer}>
        <p className={articleDate}>{new Date(article.publishedAt).toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'})}</p>
        <h2 className={articleTitle}>{article.title}</h2>
        <div className={articleExcerpt}>
          <BlockContent 
          blocks={article._rawBody} 
          projectId="bhj0qb5m"
          dataset="production"
          />       
        </div>
        <p className={fakeLink}>Read More</p>
      </div>
    </Link>
  )
}

export default function BlogPage({ data: {allSanityBlogPost} }) {
  return (
    <Layout containerStyle="noLanding">
      <Seo title="Blog" />
      {
        allSanityBlogPost.edges.map(edge => <ArticleCard article={edge.node} key={edge.node.publishedAt} />)
      }
    </Layout>
  )
}
export const query = graphql`
  {
    allSanityBlogPost {
      edges {
        node {
          _rawBody
          title
          slug {
            current
          }
          publishedAt
          mainImage {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

